button {
  user-select: none;
}

.clientDetails-bInvoice {
  margin-top: 10px;
}

.clientDetails-bInvoice>h2{
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.date-bInvoice{
  margin-top: 10px;
  margin-bottom: 14px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.date-bInvoice>ul{
  list-style: none;
}

.date-bInvoice>ul>li{
  padding: 4px;
}

.date-bInvoice>ul>li>span{
  font-weight: bold;
}

.date-list-bInvoice{
  background-color: #f4f4f5;;
}


.footer2-bInvoice{
  border-top: 2px;
  border-color: #1761d0;
  padding: 5rem;
}

.footer2-bInvoice>ul{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.footer2-bInvoice>ul>li {
  margin: 0.5rem 1rem;
}

.footer2-bInvoice>ul>li>span{
  font-weight: bold;
}


.header2-bInvoice{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.header2-bInvoice>div>h1{
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 3px;
  letter-spacing: 0.05em;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.mainDetails-bInvoice{
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}

.mainDetails-bInvoice>h2{
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 1px;
}

.notes-bInvoice{
  margin-top: 10px;
  margin-bottom: 5px;
}

.notes-bInvoice>p{
  width: 50%;
  text-align: justify;
}


.mainInvoiceForm-bInvoice{
  margin: 1.25rem;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2.5rem;
  align-items: start;
  max-width: 1920px;
  margin: auto;
}

.InvoiceForm-bInvoice{
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
              0 1px 2px rgba(0, 0, 0, 0.24);
}

.InvoiceForm-container-bInvoice{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InvoiceForm-article-bInvoice{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2.5rem;
}

.InvoiceForm-div-bInvoice{
  display: flex;
  flex-direction: column;
}

.InvoiceForm-div-bInvoice>label{
  margin-bottom: 0.3rem;
  font-size: 1.05rem;
  font-weight: 600;
  color: #383838;
}

.InvoiceForm-div-bInvoice>input{
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
  color: #616161;
  border: 2px solid hsl(217, 91%, 60%, 30%);
  outline: none;
}

.InvoiceForm-div-bInvoice>input:focus{
  border: 2px solid hsl(217, 91%, 60%);
}

.textArea-label-bInvoice{
  margin-bottom: 0.3rem;
  font-size: 1.05rem;
  font-weight: 600;
  color: #383838;
}

.textArea-bInvoice{
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
  color: #616161;
  border: 2px solid hsl(217, 91%, 60%, 30%);
  outline: none;
}

.textArea-bInvoice:focus {
  border: 2px solid hsl(217, 91%, 60%);
}

.invoice__preview-bInvoice {
  margin-top: 1.25rem;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0.25rem;
}


.invoice__preview-bInvoice button{
  background: linear-gradient(to right, #194CA3, #5c31e6);
  color: white;
  margin-left: 1.25rem;
  font-weight: 700;
  padding-top: 0.5rem; 
  padding-bottom: 0.5rem;
  padding-left: 2rem; 
  padding-right: 2rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 3px rgb(0, 0, 0);
  border: none;
  transition-property: all;
  transition: transform 0.3s;
  cursor: pointer;
}

.invoice__preview-bInvoice button:hover{
  transform: translateY(-3px);
}

.table2-bInvoice{
  width: 100%;
  margin-bottom: 10px;
}

.table2-bInvoice>thead>tr{
  padding: 1px;
  background-color: #f4f4f5;
}

.table2-bInvoice td{
  font-weight: bold;
}

.table2-bInvoice tbody>tr{
  height: 10px;
}

.totalAmount-bInvoice>h2{
  color: #2d3748; 
  font-size: 2.25rem;
  display: flex;
  align-items: end;
  justify-content: end;
  font-weight: bold;
}

.tableForm-div-bInvoice{
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.tableForm-bInvoice{
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px;
}

.addItem-bInvoice{
  background: linear-gradient(to right, #194CA3, #5c31e6);
  color: white;
  font-weight: 700;
  padding-top: 0.5rem; 
  padding-bottom: 0.5rem;
  padding-left: 2rem; 
  padding-right: 2rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 3px rgb(0, 0, 0);
  border: none;
  transition-property: all;
  transition: transform 0.3s;
  cursor: pointer;
}

.addItem-bInvoice:hover{
  transform: translateY(-3px);
}

.table-items-bInvoice{
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
}

.table-items-bInvoice>thead>tr{
  background-color: #e1e7eb;
  padding: 1px;
}

.table-items-bInvoice>thead>tr>td{
  font-weight: bold;
}

.table-items-bInvoice>tbody>tr{
  height: 10px;
}

.editRow-bInvoice{
  color: #48bb78; 
  font-weight: 700; 
  font-size: 1.875rem;
}

.deleteRow-bInvoice{
  color: #ef4444; 
  font-weight: 700; 
  font-size: 1.875rem;
}

.totalAmount2-bInvoice{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: #1f2937; 
  font-size: 2.25rem; 
  font-weight: 700;
}

.tableForm-div-bInvoice>label{
  margin-bottom: 0.3rem;
  font-size: 1.05rem;
  font-weight: 600;
  color: #383838;
}

.tableForm-div-bInvoice>input{
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
  color: #616161;
  border: 2px solid hsl(217, 91%, 60%, 30%);
  outline: none;
}

.tableForm-div-bInvoice>input:focus{
  border: 2px solid hsl(217, 91%, 60%);
}