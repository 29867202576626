code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul{
list-style: none;
}

a {
text-decoration: none;
color: white;
}

.menu-trigger-dropdown img{
position: absolute;
top: 20px;
right: 20px;
height: 40px;
width: 40px;
border-radius: 50%;
overflow: hidden;
cursor: pointer;
}

.dropdown-menu{
position: absolute;
top: 100px;
right: 20px;
background-color: rgb(134, 152, 251) ;
border-radius: 10px;
padding: 10px 20px;
width: 200px;
}

.dropdown-menu>img{
    width: 100px;
    margin-left: 50px;
    border-radius: 50px;
    cursor: pointer;
}

.dropdown-menu::before{
content: '';
position: absolute;
top: -5px;
right: 20px;
height: 20px;
width: 20px;
background: var(--secondary-bg);
transform: rotate(45deg);
}

.dropdown-menu.active{
opacity: 1;
visibility: visible;
transform: translateY(0);
transition: var(--speed) ease;
}

.dropdown-menu.inactive{
opacity: 0;
visibility: hidden;
transform: translateY(-20px);
transition: var(--speed) ease;
}


.dropdown h3{
width: 100%;
text-align: center;
font-size: 18px;
padding: 20px 0;
font-weight: 500;
font-size: 18px;
color: var(--primary-text-color);
line-height: 1.2rem;
}

.dropdown h3 span{
font-size: 14px;
color: var(--secondary-text-color);
font-weight: 400;
}

.dropdown-menu ul li{
padding: 10px  0;
border-top: 1px solid rgba(255, 255, 255, 0);
}

.dropdown-menu ul li:hover a{
color: rgb(212, 33, 9);
cursor: pointer;
}

.dropdown-menu ul li:hover img{
opacity: 1;
cursor: pointer;
}

.dropdownItem{
display: flex;
margin: 10px auto;
}

.dropdownItem img{
max-width: 20px;
margin-right: 10px;
opacity: 0.5;
transition: var(--speed);
}

.dropdownItem a{
max-width: 100px;
margin-left: 10px;
transition: var(--speed);
}
