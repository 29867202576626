body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth; 
}

.form-container{
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  min-width: 500px;
  height: 100%;  
  align-items: center;
  background-color: #6E85FF;
  border: none;
  border-radius: 35px;
  color: #ffffff;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
  margin-right: 300px;
  margin-left: 300px;
  margin-bottom: 100px;
  box-shadow: 1px 3px 15px rgb(0,0,0);
}

.logo-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding-top: 50px;
  margin-bottom: 0px;
}

.logo-container>img{
  width: 150px;
  border-radius: 200px;
}

.logo-container>h1{
  font-size: 45px;
  font-family: 'Times New Roman', Times, serif;
}

.instruction{
  margin-top: 30px;
  margin-bottom: 10px;
}

.login-form,.register-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 30px;
  gap: 20px
}

.login-form>input,.register-form>input{
  width: 400px;
  height: 45px;
  border-radius: 7px;
  border: none;
  font-size: 17px;
  padding-left: 10px;
  box-shadow: inset 1px 1px 3px rgb(0,0,0);
}

.login-form>input::placeholder,.register-form>input::placeholder{
  font-size: 17px;
  color:rgb(112, 112, 112);
}

.submit{
  width: 410px;
  height: 45px;
  border-radius: 7px;
  border: none;
  background-color: rgb(10, 203, 10);
  cursor: pointer;
  color: white;
  font-size:20px;
  transition: opacity 0.2s, transform 0.2s, box-shadow 0.2s;
  margin-top: 20px;
}

.submit:hover{
  transform: translateY(-2px);
  box-shadow: 3px 3px 8px rgba(63, 63, 63, 0.817);
}

.submit:active{
  opacity: 0.7;
}

.register-form>p{
  font-size: 18px;
  margin: 0px;
  margin-bottom: 10px;
}

.form-container>p{
  font-size: 17px;
  margin-bottom: 30px;
  margin-top: 0px;
}



