.board-fTimeSheet {
  min-width: 290px;
  width: 290px;
  max-height: 100%;
  flex-basis: 290px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(215, 198, 241);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}

.board_header-fTimeSheet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.board_header_title-fTimeSheet {
  font-weight: bold;
  font-size: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: 'Times New Roman', Times, serif;
}

.board_header_title-fTimeSheet span {
  color: rgb(145, 145, 145);
}

.board_header_title_more-fTimeSheet {
  cursor: pointer;
  position: relative;
}

.board_dropdown-fTimeSheet {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.12);
  padding: 10px;
  width: 150px !important;
  cursor: default;
  border-radius: 10px;
  transition: opacity 0.2s;
}

.board_dropdown-fTimeSheet:active{
  opacity: 0.8;
}

.board_dropdown-fTimeSheet p {
  cursor: pointer;
}

.board_cards-fTimeSheet {
  background-color: #e7e6e6;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.board_add-card-fTimeSheet {
  background: linear-gradient(to right, #194CA3, #5c31e6);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  width: 245px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
}

.board_add-card-fTimeSheet:hover {
  transform: translateY(-3px);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.957);
}

.board_add-card-fTimeSheet:active{
  opacity: 0.8;
}

.board_add-card_edit-fTimeSheet {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
