.card-fTimeSheet {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.card_top-fTimeSheet {
  display: flex;
  align-items: flex-start;
}

.card_top_labels-fTimeSheet {
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 14px;
  line-height: 21px;
}

.card_top_labels-fTimeSheet label {
  border-radius: 40px;
  padding: 4px 12px;
  background-color: gray;
  color: #fff;
  width: fit-content;
}

.card_top_more-fTimeSheet {
  width: 30px;
  height: 20px;
  transform: translateX(15px);
  flex: 1;
  cursor: pointer;
  opacity: 0;
  transition: 200ms;
}

.card-fTimeSheet:hover .card_top_more-fTimeSheet {
  opacity: 1;
}

.card_title-fTimeSheet {
  flex: 1;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.875rem;
}

.card_footer-fTimeSheet {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_footer_item-fTimeSheet {
  border-radius: 40px;
  padding: 4px 12px;
  background-color: #f8f8f8;
  color: #000;
  width: fit-content;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.card_footer_icon-fTimeSheet {
  height: 13px;
  width: 13px;
}

.card_footer_user-fTimeSheet {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
}
